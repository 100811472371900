/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import ContactUs from 'views/ContactUs';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';

import SEO from '../src/components/SEO';

export const Head = () => (
  <>
    <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "https://www.vrgeoscience.com",
          "name": "VRGeoscience Limited",
          "logo": "https://www.vrgeoscience.com/images/vrgeoscience_logo.svg",
          "contactPoint": {
            "@type": "ContactPoint",
            "email": "support@vrgeoscience.com",
            "contactType": "Customer Support"
          }
        }
      `}
    </script>
    <SEO title="Contact Us" />;
  </>
);

const ContactUsSidebar = () => {
  return <WithLayout component={ContactUs} layout={Main} />;
};

export default ContactUsSidebar;
