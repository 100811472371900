import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import Grid from '@mui/material/Grid/Grid';
import SectionHeader from 'components/molecules/SectionHeader/SectionHeader';
import HeroShaped from 'components/organisms/HeroShaped/HeroShaped';
import { StaticImage } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
  map: {
    zIndex: 9,
  },
  image: {
    objectFit: 'cover',
  },
  form: {
    '& .MuiTextField-root': {
      background: theme.palette.background.paper,
    },
    '& .MuiOutlinedInput-input': {
      background: theme.palette.background.paper,
    },
  },
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  heroleftSide: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(6),
    },
  },
}));

class HubspotForm extends React.Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '7712469',
          formId: '292478f0-1016-4dea-bcf0-4da9c8fc385f',
          target: '#hubspotForm',
        });
      }
    });
  }

  render() {
    return (
      <div>
        <div id="hubspotForm"></div>
      </div>
    );
  }
}

const Form = (props) => {
  const { data, className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <HeroShaped
        leftSide={
          <div className={classes.heroleftSide}>
            <SectionHeader
              title="Contact us!"
              subtitle="We will get back to you as soon as possible."
              data-aos="fade-up"
              align="left"
            />
            <div className={classes.form}>
              <Grid container spacing={isMd ? 4 : 2}>
                <Grid item xs={12} data-aos="fade-up">
                  <HubspotForm />
                </Grid>
              </Grid>
            </div>
          </div>
        }
        rightSide={
          <StaticImage
            src="images/background1.jpg"
            alt="Contact Us"
            placeholder="blurred"
            className={classes.image}
          />
        }
      />
    </div>
  );
};

Form.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
};

export default Form;
