import React from 'react';
import { Form } from './components';

import { mapData } from './data';

const ContactUs = () => (
  <div>
    <Form data={mapData} />
  </div>
);

export default ContactUs;
